<template>
  <transition name="fade">
    <signin v-if="isLoginOpen" @close="onCloseSignin" @signup="isLoginOpen = false;isSignupOpen = true"/>
  </transition>

  <transition name="fade">
    <TMmodal v-show="TMOpen" :isOpen="TMOpen" :type="'header'" @close="onCloseTM"/>
  </transition>

  <transition name="fade">
    <join v-if="isSignupOpen" @close="onCloseSignup"/>
  </transition>

  <transition name="fade">
    <join-complete v-if="isSignupCompleteOpen" @close="isSignupCompleteOpen = false;isLoginOpen = true"/>
  </transition>

  <transition name="fade">
    <new-message v-if="isUnReadMessage" />
  </transition>
  <transition name="slide">
     <div class="leftHeader" :class="{ 'out': nside }" v-if="nside && commonCodeByOrder && gameCount">
        <ul>
          <li>
            <div class="leftNav">
              <!-- <ul>
                <li @click="goPageByName('charge')" :class="{'on': $route.name === 'charge'}"><a><em class="lin"></em>입금</a></li>
                <li @click="goPageByName('exchange')" :class="{'on': $route.name === 'exchange'}"><a><em class="lout"></em>출금</a></li>
                <li @click="goPageByName('bettingList')" :class="{'on': $route.name === 'bettingList'}"><a><em class="lbet"></em>베팅내역</a></li>
                <li @click="goPageByName('point')" :class="{'on': $route.name === 'pointUse'}"><a><em class="lpoint"></em>포인트</a></li>
                <li @click="goPageByName('csCenter')" :class="{'on': $route.name === 'qnaList'}"><a><em class="lcs"></em>고객센터</a></li>
                <li @click="goPageByName('notice')" :class="{'on': $route.name === 'noticeList'}"><a><em class="lnotice"></em>공지사항</a></li>
                <li @click="goPageByName('faq')" :class="{'on': $route.name === 'faqList'}"><a><em class="lfaq"></em>자주하는질문</a></li>
                <li  :class="{'on': $route.name === 'partner'}" v-if="$route.name === 'partner'">
                  <a :href="'/ZJ5'" target="_blank" v-if="userData && userData.isPartnerMember && userData.isPartnerMember === 'Y'"><em class="linfo"></em>{{ $t('front.common.partner') }}</a>
                </li>
              </ul> -->
              <ul class="left-01">
                <li @click="goPageByName('charge')">
                </li>
                <li @click="goPageByName('exchange')">
                </li>
                <li @click="goPageByName('csCenter')">
                </li>
                <li @click="goPageByName('bettingList')">
                </li>
                <li @click="goPageByName('noticeList')">
                </li>
                <li @click="goPageByName('info')">
                </li>
              </ul>
              <ul class="left-02">
                <li @click="goPageByName('listcasino')"></li>
                <!-- <li @click="goPageByName('listhotel')"></li> -->
                <li @click="goPageByName('listslot')"></li>
                <!-- <li @click="goPageByName('listmini')"></li> -->
                <!-- <li @click="goPageByName('listsports')"></li> -->
              </ul>
            </div>
          </li>
        </ul>
     </div>
  </transition>
  <div class="rightHeader" v-if="userData">
    <ul>
      <li>
        <div class="userInfo">
          <p>반갑습니다!</p>
          <span><em>{{userData.memNick}}</em> 님</span>
          <div class="userDetail">
            <ul class="leftDetail">
              <li>{{thousand(userData.cashAmt ? userData.cashAmt.toString(): '0')}}원</li>
              <li @click="goPageByName('point')">보유포인트 {{thousand(userData.pointAmt ? userData.pointAmt.toString(): '0')}} P</li>
            </ul>
            <ul class="rightDetail">
              <li><em>쪽지함</em> {{thousand(unReadMessageCount ? unReadMessageCount.toString() : '0')}}통</li>
            </ul>
          </div>
        </div>
      </li>
      <li>
        <ul class="snsInfo">
          <li></li>
        </ul>
      </li>
    </ul>
  </div>
  <header v-if="gameCount" id="mainheader" :class="{ 'side': nside }">
    <ul class="header">
      <li class="logowrap">
        <a @click="goPageByName('main')">
          <img src="@/assets/img/site_logo.png" />
        </a>
        <div class="topbannerin">
          <div class="speakerIcon"><img src="@/assets/img/icon_mega.svg"/></div>
          <span class="">{{$t('front.header.msg')}}</span>
        </div>
      </li>
      <li class="menuWrap">
        <div class="naviwrap" >
          <template v-if="!userData">
            <div class="loginWrap">
              <ui-button :className="'loginbtn headerbtn'" :text="$t('front.gnb.login')" @click="onClickLogin"/>
              <ui-button :className="'joinbtn headerbtn'" :text="$t('front.common.signup')" @click="onClickSignup"/>
            </div>
          </template>
          <template v-else>
            <ul class="coninpoint" v-if="userData">
              <li @click="goPageByName('bettingList')">
                <span>베팅내역</span>
              </li>
              <li @click="goPageByName('noticeList')">
                <span>공지사항</span>
              </li>
              <li @click="goPageByName('qnaList')">
                <span>고객센터</span>
              </li>
              <li @click="goPageByName('messageList')">
                <span>쪽지함</span>
              </li>
              <li @click="goPageByName('point')">
                <span>포인트</span>
              </li>
              <li @click="goPageByName('info')">
                <span>내정보</span>
              </li>
              <li @click="signOut">
                <span>로그아웃</span>
              </li>
            </ul>
            <!-- <ul class="coninpoint" v-if="userData">
              <li>
                 <i class="icon"><img src="@/assets/img/icon/icon_user.svg" /></i>
                 <span @click="goPageByName('messageList')">
                    <em>받은 쪽지</em>
                    {{thousand(unReadMessageCount ? unReadMessageCount.toString() : '0')}}
                 </span>
              </li>
              <li>
                 <span>
                    <em>{{$t('front.give.haveCash')}}</em>
                    {{thousand(userData.cashAmt ? userData.cashAmt.toString(): '0')}}
                 </span>
              </li>
              <li>
                 <button class="headerbtn_coinplus" @click="goPageByName('charge')">
                    <img src="@/assets/img/icon/icon_plusw.svg" />
                 </button>
              </li>
              <li>
                <span>
                    <em>{{$t('front.cash.nav4')}}</em>
                    {{thousand(userData.pointAmt ? userData.pointAmt.toString() : '0')}}
                 </span>
              </li>
              <li>
                <button class="headerbtn_coinplus" @click="goPageByName('point')">
                  <img src="@/assets/img/icon/icon_plusw.svg" />
                </button>
              </li>
            </ul> -->
          </template>
        </div>
      </li>
    </ul>
    <ul class="header-bot">
      <li @click="goPageByName('listcasino')">라이브카지노</li>
      <!-- <li @click="goPageByName('listhotel')">호텔카지노</li> -->
      <li @click="goPageByName('listslot')">슬롯게임</li>
      <!-- <li @click="goPageByName('listmini')">미니게임</li> -->
      <!-- <li @click="goPageByName('listsports')">스포츠</li> -->
      <li>
        <a :href="'/ZJ5'" target="_blank" v-if="userData.isPartnerMember && userData.isPartnerMember === 'Y'" style="color:#61463a;">파트너페이지</a>
      </li>
    </ul>
  </header>
</template>

<script>

import UiButton from '@/components/ui/UiButton'

import { mapState } from 'vuex'
import Join from '@/components/member/Join'
import JoinComplete from '@/components/member/JoinComplete'
import Signin from '@/components/member/Signin'
import TMmodal from '@/components/common/TotalMoney'
import { TRIPLE_GAME_LIST } from '@/libs/constants'
import NewMessage from '@/components/member/Message.vue'
import { getMainCashStatus } from '@/api/main'

export default {
  name: 'Header',
  components: {
    NewMessage,
    Signin,
    JoinComplete,
    Join,
    UiButton,
    TMmodal
  },
  data () {
    return {
      menuList: [],
      TMOpen: false,
      isLoginOpen: false,
      isSignupOpen: false,
      isSignupCompleteOpen: false,
      showMenu: false,
      showMenuMoney: false,
      showMenuLang: false,
      isAlertOpen: false,
      isGameOpen: false,
      isHgameOpen: false,
      isSlotOpen: false,
      isSportsOpen: false,
      isMiniOpen: false,
      isCashOpen: false,
      tripleGameList: TRIPLE_GAME_LIST,
      nside: true,
      cashOutList: [],
      leftOutTab: 'charge'
    }
  },
  watch: {
    $route: {
      handler (current) {
        console.log('$route name', this.$route)
        this.gnbReset()
      }
    }
  },
  props: ['selectedMenu'],
  created () {
    this.setMenu()
    this.getHeadMsgList()
    this.loadCashStatus()
  },
  computed: {
    ...mapState([
      'userData',
      'coinAmt',
      'commonCodeByOrder',
      'gameCount',
      'unReadMessageCount',
      'siteOption'
    ]),
    isUnReadMessage () {
      return this.unReadMessageCount && this.unReadMessageCount && this.unReadMessageCount !== '0' && this.$route.name.indexOf('message') === -1 && this.$route.name.indexOf('msg') === -1
    }
  },
  methods: {
    loadCashStatus () {
      getMainCashStatus({}).then(res => {
        const result = res.data
        if (result.resultCode === '0') {
          console.log(result.data)
          this.cashInList = result.data.cashInList.slice(0, this.listMaxCount)
          this.cashOutList = result.data.cashOutList.slice(0, this.listMaxCount)
        }
      })
    },
    slotOpen (event, groupCode, codeName, code) {
      this.emitter.emit(event, { groupCode, codeName, code })
    },
    gnbReset () {
      this.isGameOpen = false
      this.isHgameOpen = false
      this.isSlotOpen = false
      this.isSportsOpen = false
      this.isMiniOpen = false
      this.isCashOpen = false
      this.showMenu = false
      this.showMenuLang = false
    },
    gnbMenuOpen (type) {
      this.gnbReset()
      if (type === 'game') {
        this.isGameOpen = true
      } else if (type === 'hgame') {
        this.isHgameOpen = true
      } else if (type === 'slot') {
        this.isSlotOpen = true
      } else if (type === 'cash') {
        this.isCashOpen = true
      } else if (type === 'mypage') {
        this.showMenu = true
      } else if (type === 'lang') {
        this.showMenuLang = true
      } else if (type === 'sport') {
        this.isSportsOpen = true
      } else if (type === 'minigame') {
        this.isMiniOpen = true
      }
    },
    getHeadMsgList () {
      // getHeadMsg({}).then(response => {
      //   console.log(response)
      // })
    },
    setMenu () {
      const list = this.$router.getRoutes()
      for (let i = 0, iLen = list.length; i < iLen; i++) {
        const item = list[i]
        if (item.meta && item.meta.gnb) {
          item.isSubOpen = false
          this.menuList.push(item)
        }
      }
    },
    onClickGame (code, name) {
      this.gnbReset()
      if (code) {
        // minigame

        if (code === 'mg') {
          this.$router.push({ name: name })
        } else {
          this.onSelectGame(code)
        }
      } else {
        this.onCheck('준비 중입니다.')
      }
    },
    onClickGnb (item) {
      const name = item.name
      const meta = item.meta
      if (meta.game) {
        this.onSelectGame(meta.gameCode)
      } else {
        this.$router.push({ name: name })
      }
    },
    async onClickTM () {
      const confirmMessage = '통합머니 전환을 진행하시겠습니까?'
      const confirmResult = await this.onConfirm(confirmMessage)
      if (confirmResult) {
        this.TMOpen = true
      }
    },
    onCloseTM () {
      this.TMOpen = false
    },
    onClickLogin () {
      this.isLoginOpen = true
      this.isSignupOpen = false
    },
    onClickSignup () {
      this.isLoginOpen = false
      this.isSignupOpen = true
    },
    onCloseSignin (value) {
      this.isLoginOpen = false
      if (value === 'join') {
        this.isSignupOpen = true
      }
      if (value === 'signin') {
        // location.reload()
      }
    },
    onCloseSignup (status) {
      this.isSignupOpen = false
      if (status) {
        this.isSignupCompleteOpen = true
      }
    }
  }
}
</script>

<style scoped src="@/styles/header.css"></style>
<style scoped src="@/styles/footer.css"></style>
<style scoped src="@/styles/common.css"></style>
