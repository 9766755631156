export const gamelist = {
  name: 'gamelist',
  path: '/gamelist',
  component: () => import('@/views/member/gamelist'),
  redirect: {
    name: 'listcasino'
  },
  meta: {
    layout: 'mobile-default',
    text: '게임리스트',
    gnb: false,
    guestOnly: false
  },
  children: [
    {
      name: 'listcasino',
      path: '/gamelist/casino',
      component: () => import('@/views/member/gamelist/casino'),
      meta: {
        layout: 'mobile-default',
        text: '카지노'
      }
    },
    {
      name: 'listslot',
      path: '/gamelist/slot',
      component: () => import('@/views/member/gamelist/slot'),
      meta: {
        layout: 'mobile-default',
        text: '슬롯게임'
      }
    },
    {
      name: 'listhotel',
      path: '/gamelist/hotel',
      component: () => import('@/views/member/gamelist/hotel'),
      meta: {
        layout: 'mobile-default',
        text: '슬롯게임'
      }
    },
    {
      name: 'listmini',
      path: '/gamelist/mini',
      component: () => import('@/views/member/gamelist/minigame'),
      meta: {
        layout: 'mobile-default',
        text: '슬롯게임'
      }
    },
    {
      name: 'listsports',
      path: '/gamelist/sports',
      component: () => import('@/views/member/gamelist/sports'),
      meta: {
        layout: 'mobile-default',
        text: '슬롯게임'
      }
    }
  ]
}
